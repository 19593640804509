@font-face {
  font-family: 'Oswald';
  src: url('./Oswald-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./Mulish-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro-Display';
  src: url('./SF-Pro-Display-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFPr-Regular';
  src: url('./SF-Pro-Text-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFPro-Bold';
  src: url('./SF-Pro-Text-Bold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFPro-Medium';
  src: url('./SF-Pro-Text-Medium.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFPro-Semibold';
  src: url('./SF-Pro-Text-Semibold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro-Semibold';
  src: url('./SF-Pro-Text-Semibold.otf');
  font-weight: normal;
  font-style: normal;
}
